<template>
  <v-dialog
    @input="dialogsChangePrivacePolice($event)"
    :value="show"
    persistent
    max-width="900"
    v-if="show"
  >
    <v-card max-height="720" height="80vh" dark color="black">
      <v-btn
        icon
        dark
        @click="dialogsChangeVideoShow({ status: false })"
        color="error"
        small
        absolute
        top
        right
        fab
        class="mt-6"
      >
        <v-icon>close</v-icon>
      </v-btn>

      <v-row class="py-12 fill-height">
        <youtube
          :video-id="videoId"
          width="100%"
          height="100%"
          :player-vars="playerVars"
          ref="youtube"
        ></youtube>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  props: {},
  data() {
    return {
      playerVars: {
        autoplay: 1,
        showinfo: 0,
        rel: 0,
      },
    }
  },
  methods: {
    getId() {
      return this.$youtube.getIdFromUrl(this.url)
    },
    ...mapActions({
      dialogsChangeVideoShow: 'dialogs/changeVideoShow',
    }),
  },
  computed: {
    videoId() {
      return this.getId()
    },
    player() {
      return this.$refs.youtube.player
    },
    ...mapState({
      url: (state) => state.dialogs.videoUrl,
    }),
    ...mapGetters({
      show: 'dialogs/videoShow',
    }),
  },
  mounted() {},
  watch: {
    show() {
      // if (this.show) this.$refs.youtube.player.playVideo()
      // if (!this.show) this.$refs.youtube.player.stopVideo()
    },
  },
}
</script>

<style scoped></style>
